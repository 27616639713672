<template>
  <div :class="{ 'device-mobile': $device.isMobile }">
    <!-- BEGIN::Header -->
    <LazyHydrate when-idle>
      <AppHeader />
    </LazyHydrate>
    <!-- END::Header -->
    <client-only> <KftNotifications /> </client-only>

    <!-- BEGIN::Page Content -->
    <div id="layout">
      <transition name="sf-fade">
        <nuxt :key="$route.fullPath" />
      </transition>
      <client-only>
        <CartSidebar />
      </client-only>
    </div>
    <!-- END::Page Content -->

    <!-- BEGIN::Footer -->
    <LazyHydrate when-idle>
      <AppFooter />
    </LazyHydrate>
    <!-- END::Footer -->
  </div>
</template>

<script>
import LazyHydrate from "vue-lazy-hydration";
import { onMounted } from "@nuxtjs/composition-api";
import {
  useDefaultErrorHandlers,
  useRegisterCartValidationPolling,
} from "../composables";
import KftNotifications from "../components/organisms/KftNotifications/KftNotifications.vue";
import cacheControl from "~/helpers/cacheControl";
import AppHeader from "~/components/AppHeader.vue";
import AppFooter from "~/components/AppFooter.vue";
import CartSidebar from "~/components/CartSidebar.vue";
import SeoHelper from "~/helpers/seo/SeoHelper";

export default {
  name: "DefaultNoAppLinksLayout",

  components: {
    KftNotifications,
    LazyHydrate,
    AppHeader,
    AppFooter,
    CartSidebar,
  },

  middleware: cacheControl({
    values: {
      "max-age": 60 * 60 * 6,
      "stale-when-revalidate": 5,
    },
  }),

  setup() {
    const { registerDefaultErrorHandlers } = useDefaultErrorHandlers();
    useRegisterCartValidationPolling();

    onMounted(() => {
      registerDefaultErrorHandlers();
    });
  },

  head() {
    const routeName = this.$route?.name?.split("___")[0] || "";
    const i18nHead = this.$nuxtI18nHead({ addSeoAttributes: true });

    const seoHelper = new SeoHelper(this, {
      allowedParams: [],
      meta: {
        "og:site_name": this.$t("general.titles.base"),
        "apple-mobile-web-app-title": this.$t("general.titles.base"),
        title:
          this.$t(`general.titles.${routeName}`) +
          " | " +
          this.$t("general.titles.base"),
        "og:title":
          this.$t(`general.titles.${routeName}`) +
          " | " +
          this.$t("general.titles.base"),
        description:
          this.$te(`general.descriptions.${routeName}`) &&
          this.$t(`general.descriptions.${routeName}`),
        "og:description":
          this.$te(`general.descriptions.${routeName}`) &&
          this.$t(`general.descriptions.${routeName}`),
        "og:image":
          this.$config.baseUrl + this.$t("general.openGraphImages.default"),
        "og:type": "website",
      },
    });

    const canLink = seoHelper.getCanonicalLink();

    if (canLink) {
      seoHelper.createOrOverwriteMetaTag(
        "og:url",
        seoHelper.getCanonicalLink(),
      );
    }

    const seoHelperMeta = seoHelper.getHeadMeta();

    return {
      ...i18nHead,
      meta: [...seoHelperMeta],
      title: this.$t(`general.titles.${routeName}`),
      titleTemplate: "%s" + " | " + this.$t("general.titles.base"),
    };
  },
};
</script>

<style lang="scss">
#layout {
  box-sizing: border-box;
  padding: var(--navbar-height) var(--spacer-4);
  --overlay-z-index: 2;

  @include for-desktop {
    max-width: 1240px;
    margin: auto;
  }
}

.no-scroll {
  overflow: hidden;
  height: 100vh;
}

// Reset CSS
html {
  width: auto;
  @include for-tablet-and-down {
    overflow-x: hidden;
  }
}

body {
  overflow-x: hidden;
  color: var(--c-text);
  font-size: var(--font-size--base);
  font-family: var(--font-family--primary);
  margin: 0;
  padding: 0;
}
</style>
